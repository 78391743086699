.report-style-class {
  width: 1400px;
  height: 900px;
}
iframe {
  border: none;
}
.tablet{
  display: flex;
  justify-content: center;
}