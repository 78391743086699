
iframe {
  border: none;
}
.mobile{
  display: flex;
  justify-content: center;
}

.report-style-class {
  width: 1400px;
  height: 900px;

}

.report-style-class-Show {
  width: 1400px;
  height: 900px;

}

.report-style-class-hide {
display: none;
}